import React from "react";
import PropTypes from "prop-types";
import Settings from "./settings";
import classNames from "classnames/bind";
import styles from "./header.css";
import Navigation from "../navigation";
import { Link } from "react-router-dom";
const cx = classNames.bind(styles);

export default function SiteHeader(props) {
  const _renderGearMenu = () => (
    <Settings
      key="gm"
      className={cx("navigation")}
      accessToken={props.accessToken}
    />
  );

  const _renderDJLogo = () => {
    return (
      <Link
        to="/"
        style={{ color: "white" }}
        tabIndex={0}
        className="header--dj-logo---22SE4"
      >
        <img
          src={require("../../assets/powered-by-logo.svg")}
          alt="Dow Jones"
        />
      </Link>
    );
  };

  const _renderTop = () => {
    return (
      <div className="cb-row header--top---1pix3">
        <div className="header--top-left---13Afz clearfix">
          {_renderDJLogo()}
        </div>
        {_renderGearMenu()}
      </div>
    );
  };

  return (
    <header className="header--component---3-qdu clearfix">
      {_renderTop()}
      <Navigation location={props.location} />
    </header>
  );
}
SiteHeader.propTypes = {
  className: PropTypes.any,
  accessToken: PropTypes.string,
  entityHeader: PropTypes.object,
  inProgress: PropTypes.bool,
  location: PropTypes.object,
  suggestions: PropTypes.object,
  entitlements: PropTypes.object,
  showSearchBar: PropTypes.bool,
};
