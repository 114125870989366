import React from "react";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { useAuth0 } from "../../react-auth0-spa";

function Settings(props) {
  const { isAuthenticated, logout } = useAuth0();
  const { Toggle: DropdownToggle, Menu: DropdownMenu } = Dropdown;

  const options = [];

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  options.push(
    <Dropdown.Item
      key="signOutOption"
      eventKey="2"
      className="btn-signOut"
      onClick={() => logoutWithRedirect()}
    >
      Log Out
    </Dropdown.Item>
  );
  if (!isAuthenticated) return null;
  return (
    <div className="settings--component---2q6jT header--navigation---3ayCU">
      <ButtonToolbar>
        <Dropdown id="settings-dropdown" pullRight>
          <DropdownToggle noCaret title="">
            <img src={require("../../assets/settings.svg")} alt="Settings" />
          </DropdownToggle>
          <DropdownMenu bsStyle="link" id="dropdown-no-caret">
            {options}
          </DropdownMenu>
        </Dropdown>
      </ButtonToolbar>
    </div>
  );
}

export default Settings;
